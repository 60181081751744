<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="d-flex w-100">
          <div class="h3">Daftar {{ title }}</div>
        </div>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <div class="table-responsive mt-md-n5">
        <table class="table table-bordered table-row-bordered">
          <thead class="fw-bolder border-bottom">
            <th>Nama User</th>
            <th>Email</th>
            <th>Telepon</th>
            <th>Aksi</th>
          </thead>
          <tbody>
            <tr v-for="(item, i) in data" :key="i">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.telp }}</td>
              <td>
                <button
                  class="rounded btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  @click="getDetail(item.id)"
                >
                  Lihat data
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Detail Account</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row border-bottom">
            <div class="col-md-4">Nama <span class="float-end">:</span></div>
            <div class="col-md-6">
              {{ detail.name }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-md-4">Email <span class="float-end">:</span></div>
            <div class="col-md-6">
              {{ detail.email }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-md-4">Telepon <span class="float-end">:</span></div>
            <div class="col-md-6">
              {{ detail.telp }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-md-4">
              Kecamatan <span class="float-end">:</span>
            </div>
            <div class="col-md-6">
              {{ detail.kecamatan }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-md-4">
              Kelurahan <span class="float-end">:</span>
            </div>
            <div class="col-md-6">
              {{ detail.kelurahan }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-md-4">RT/RW <span class="float-end">:</span></div>
            <div class="col-md-6">
              {{ detail.rt + " / " + detail.rw }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-dismiss="modal"
          >
            Tutup
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            data-bs-dismiss="modal"
          >
            Tolak
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            data-bs-dismiss="modal"
          >
            Setujui
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default {
  setup() {
    const title = "Konfirmasi Akun";
    setCurrentPageTitle(title);

    const store = useStore();

    const data = computed(() => store.state.AccountConfirmModule.all);

    const detail = ref([]);

    store.commit("SET_ACTIVE_MENU", "admin.account.confirm");

    const getDetail = (id) => {
      detail.value = store.getters.FindAccountConfirm(id);
    };

    return {
      title,
      data,
      detail,
      getDetail,
    };
  },
};
</script>
